import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { Formik, Field, Form } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PlnInput } from '../../Components/Input';
import style from './styles';
import { searchByCedula } from '../../Core/Api/padronApi';
import REGEX from '../../Core/Utils/RegExpUtils';

const ConsultaElectores = props => {
  const { classes } = props;
  const [showSearchResult, setShowSearchResult] = useState(
    false,
  );
  const [initialValues, setInitialValues] = useState({
    cedula: '',
  });

  const [electorInfo, setElectorInfo] = useState({
    provincia: '',
    canton: '',
    distritoAdm: '',
    distritoEl: '',
    juventud: '',
    primerApellido: '',
    segundoApellido: '',
    nombre: '',
  });

  const [msjErrorCedula, setMsjErrorCedula] = useState('');

  const validationSchema = Yup.object({});

  const formikValidate = values => {
    const errors = {};
    const { cedula } = values;

    if (!cedula) {
      errors.cedula = 'Requerido';
    } else if (cedula.length <= 8) {
      errors.cedula =
        'Debe utilizar el siguiente formato: 102340567';
    } else if (!REGEX.cedula.test(cedula)) {
      errors.cedula =
        'Solo se permiten números. (NO ingrese guiones ni espacios en blanco)';
    }

    return errors;
  };

  const loadInformation = async (
    values,
    { setSubmitting },
  ) => {
    const { cedula } = values;
    setShowSearchResult(false);

    setInitialValues({
      cedula,
    });

    setSubmitting(true);

    await searchByCedula(cedula)
      .then(_response => {
        const { data } = _response;

        if (data.response.length > 0) {
          const information = data.response[0];

          setShowSearchResult(true);
          setElectorInfo({
            provincia: information.des_nombre_p,
            canton: information.des_nombre_c,
            distritoAdm: information.des_nombre_ad,
            distritoEl: information.des_nombre_d,
            juventud: information.num_edad <= 35 ? 'J' : '',
            primerApellido: information.des_apell1 || '',
            segundoApellido: information.des_apell2 || '',
            nombre: information.des_nombre,
            jrv: information.num_jvirt,
            centroVotacion: information.des_nombre_centro,
          });
        } else {
          setMsjErrorCedula(
            'El número de cédula ingresado no coincide con ninguna referencia',
          );
          setSubmitting(false);
          setShowSearchResult(false);
        }
      })
      .catch(error => {
        setShowSearchResult(false);
        setMsjErrorCedula(error);
      });
  };

  const resetForm = ev => {
    ev.preventDefault();
    setInitialValues({
      cedula: '',
    });

    setShowSearchResult(false);
  };
  return (
    <Container maxWidth="md" className={classes.container}>
      <h3>PARTIDO LIBERACIÓN NACIONAL</h3>
      <h4>TRIBUNAL DE ELECCIONES INTERNAS</h4>
      <p>2021</p>
      <Formik
        validate={formikValidate}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={loadInformation}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <React.Fragment>
              <div className={classes.boxForm}>
                <Form>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      border: 'solid thin #ccc',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        backgroundColor: '#008945',
                        color: '#fff',
                      }}
                    >
                      Consulte su lugar de votación
                    </Grid>
                    <Grid item xs={3}>
                      <></>
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Digite el número de cédula: </h4>
                      <Field
                        mask="999999999"
                        name="cedula"
                        component={PlnInput}
                        type="text"
                        style={{ marginTop: 5 }}
                      />
                      {errors.cedula && touched.cedula ? (
                        <div className={classes.formError}>
                          <Alert severity="error">
                            {errors.cedula}
                          </Alert>
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      item
                      xs={3}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
              <div className={classes.boxForm}>
                {isSubmitting ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      padding: 10,
                    }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      padding: 10,
                      color: '#cc0000',
                    }}
                  >
                    {msjErrorCedula}
                  </Grid>
                )}
              </div>
            </React.Fragment>
          );
        }}
      </Formik>
      <div className={classes.boxForm}>
        {showSearchResult && (
          <Grid
            container
            spacing={3}
            style={{
              border: 'solid thin #ccc',
              marginTop: 20,
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: '#008945',
                color: '#fff',
              }}
            >
              Resultado de Búsqueda
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>
                Provincia
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.provincia}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>Cantón</h4>
              <div className={classes.fieldFilled}>
                {electorInfo.canton}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>
                Distrito Adm.
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.distritoAdm}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>
                Distrito Electoral
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.distritoEl}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>Cédula</h4>
              <div className={classes.fieldFilled}>
                {initialValues.cedula}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>
                Juventud
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.juventud}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>JRV</h4>
              <div className={classes.fieldFilled}>
                {electorInfo.jrv}
              </div>
            </Grid>
            <Grid item xs={12} sm={3}>
              <h4 className={classes.fieldTitle}>
                Centro Votación
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.centroVotacion}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <h4 className={classes.fieldTitle}>
                Primer Apellido
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.primerApellido}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <h4 className={classes.fieldTitle}>
                Segundo Apellido
              </h4>
              <div className={classes.fieldFilled}>
                {electorInfo.segundoApellido}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <h4 className={classes.fieldTitle}>Nombre</h4>
              <div className={classes.fieldFilled}>
                {electorInfo.nombre}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={ev => resetForm(ev)}
              >
                Nueva Búsqueda
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

export default withStyles(style)(ConsultaElectores);
